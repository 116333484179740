import React from "react"
import { graphql } from "gatsby"
import {
    getLessonContentItemData,
    SEO_KEYWORDS,
    SEO_DESCRIPTION,
} from "../utils"
import Container from "../components/layout/Container"
import Header from "../components/Header"
import Space from "../components/Space"
import Content from "../components/layout/Content"
import ContentListItem from "../components/ContentListItem"
import Pagination from "../components/Pagination"
import Title from "../components/Title"
import { Helmet } from "react-helmet"
import TrainingBanner from "../components/TrainingBanner"

export default props => {
    const { edges } = props.data.allMongodbVpletaysyaLessons
    const { pageContext } = props
    return (
        <Container>
            <Helmet>
                <title>Уроки по плетению косичек</title>
                <meta name="keywords" content={SEO_KEYWORDS} />
                <meta name="description" content={SEO_DESCRIPTION} />
            </Helmet>
            <Header />
            <TrainingBanner/>
            <Space />
            <Content>
                <Title>Фото-уроки</Title>
                {edges.map((item, i) => (
                    <ContentListItem
                        key={`lessons_${i}`}
                        {...getLessonContentItemData(item)}
                    />
                ))}
                <Pagination
                    currentPage={pageContext.currentPage}
                    totalItems={pageContext.totalItems}
                    display={pageContext.limit}
                    urlFirst="/lesson"
                    url="/lesson/page/"
                />
            </Content>
            <Space />
        </Container>
    )
}

export const blogListQuery = graphql`
    query lessonsListQuery($skip: Int!, $limit: Int!) {
        allMongodbVpletaysyaLessons(
            sort: { fields: [date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    old_id
                    old_url
                    text
                    title
                    url
                    keywords
                    date
                    date1
                    description
                    published
                }
            }
        }
    }
`
